// export const WEB_API = 'http://dopa.aslsecurities.com/api/';
//export const WEB_API = 'http://202.6.17.103:8888/api/';
export const WEB_API = 'https://buriram-shop-api.makersoft.co.th/api/';
// export const WEB_API = "http://localhost:8888/api/"; 
//export const REPORT_API = "http://localhost:44374/api/";
export const REPORT_API = "https://buriram-shop-report.makersoft.co.th/api/"
export const VERSION_RELEASE = "1.1.1.4";
export const secretKey = "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3";
export const algorithm = "aes-256-ctr";
export const randomBytes = 16;
